:root {
  --show_width: 750px;
  /* 可视区宽度 */
  --header_height: 70px;
  /* header 高度 */
  --footer_height: 50px;
  /* footer 高度 */
  --carousel_height: 233px;
  /* 轮播高度 */
}

@media screen and (max-width: 420px) {
  :root {
    --show_width: 100%;
  }
}

.app {
  width: 100%;
  min-width: var(--show_width);
  height: 100%;
  position: relative;
}

.layout {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.layout.blog {
  height: 100%;
}

.footer {
  width: var(--show_width);
  margin-top: 20px;
  min-width: var(--show_width);
  height: var(--footer_height);
  line-height: var(--footer_height);
}

.page-layout-reset {
  width: var(--show_width);
  padding-top: calc(var(--header_height) + 15px);
}

@keyframes loading {
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: 256px;
  }
}

.page-loading {
  width: 100%;
  height: 100%;
}

.page-loading>.page-loading-image {
  width: 100%;
  height: 100%;
  background: url('./asset/image/cloud-5s-1084px.png');
  animation: loading 5s linear infinite;
}

.header-title {
  line-height: var(--header_height);
  font-size: 36px;
  text-align: center;
  font-weight: bold;
  color: #000;
}
