/**
* layout-type1-wrap
* 上--中--下 header--main-footer
* 铺满整个屏幕
*/

.layout-type1-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.layout-type1-wrap > .header {
  width: 100%;
  height: 70px;
  position: relative;
}

.layout-type1-wrap > .main {
  width: 100%;
  flex: 1;
  overflow-y: auto;
  margin: 30px 0;
  position: relative;
}

.layout-type1-wrap > .footer {
  width: 100%;
  height: 50px;
  position: relative;
}
