*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: none;
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  -ms-user-select: none;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

td,
th,
caption {
  font-size: 0.18rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

a {
  color: #555;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  border: none;
}

ol,
ul,
li {
  list-style: none;
}

input,
textarea,
select,
button {
  font: 0.18rem Verdana, Helvetica, Arial, sans-serif;
  border: none;
  outline: none;
}

table {
  border-collapse: collapse;
}

#root {
  width: 100%;
  height: 100%;
}